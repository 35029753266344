export default () => {
    const hostName = window.location.hostname;
    const pathName = window.location.pathname.toLowerCase();

    /* Each host name will correlate with an ibis. domain and a GTM container ID. 
     For example, any lendingtree.com url that is NOT /forms/ will use GTM-PB2FNQ and will call ibis.lendingtree.com
     If the host name is offers.magnifymoney.com, the GTM container ID will be GTM-MXN3F4 and will call ibis.magnifymoney.com
     We need to ensure that the correct GTM container ID and ibis. domain are being called for each host name/path name combination
    */
    const sgtmContainerMapping = [
        {
            stringToMatch: hostName,
            stringToCompare: 'offers.',
            gtmContainerId: hostName.includes('magnifymoney.com') ? 'GTM-MXN3F4' : '',
            containerUrl: hostName.includes('magnifymoney.com') ? 'ibis.magnifymoney.com' : ''
        },
        {
            stringToMatch: pathName,
            stringToCompare: '/forms/',
            gtmContainerId: 'GTM-W5DKKXF',
            containerUrl: 'ibis.lendingtree.com'
        },
        // Add more objects to the array as needed
        {
            stringToMatch: hostName,
            stringToCompare: 'lendingtree.com',
            gtmContainerId: (hostName.startsWith('www.')) && !hostName.includes('comparecards') ? 'GTM-PB2FNQ' : '',
            containerUrl: 'ibis.lendingtree.com'
        },
    ];


    let gtmContainerId = '';
    let containerUrl = '';
    for (const app of sgtmContainerMapping) {
        if (app.stringToMatch.includes(app.stringToCompare)) {
            gtmContainerId = app.gtmContainerId;
            containerUrl = app.containerUrl;
            if (gtmContainerId) {
                break;
            }
        }
    }

    if (
        gtmContainerId &&
        !window.disablegtm &&
        (!window.google_tag_manager ||
            (window.google_tag_manager && !window.google_tag_manager[gtmContainerId]))
    ) {
        (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({
                'gtm.start': new Date().getTime(),
                event: 'gtm.js',
            });
            var f = d.getElementsByTagName(s)[0];
            var j = d.createElement(s);
            var dl = l !== 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = `https://${containerUrl}/gtm.js?id=${i}${dl}`;
            f.parentNode.insertBefore(j, f);
        }(window, document, 'script', 'dataLayer', gtmContainerId));
    }
    return [gtmContainerId, containerUrl];
}